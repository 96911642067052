<script setup>
import Navbar from "./Partials/Navbar.vue";
import MobileNavbar from "./Partials/MobileNavbar.vue";
import MediaLinks from "./Partials/MediaLinks.vue";
import ScrollToTopButton from "@/Components/ScrollToTopButton.vue";
import Footer from "./Partials/Footer.vue";
import { Head } from "@inertiajs/vue3";

defineProps({
    locations: Object
})

const keywords = "شركة وود ميكر ش.م.م,الأثاث المكتبي,الأثاث المنزلي,فرش وتجهيز الفنادق و القري السياحية,تجهيز الحدائق و البرجولات,تجهيز و تنفيذ جميع الابواب و النوافذ الخشبية والابواب المقاومة  للحرائق,الجامعات والمدارس";
const author = "شركة وود ميكر ش.م.م";
const ogTitle = author;
const description = "شركة وود ميكر ش.م.م شركة متخصصة فى الأثاث الخشبى تأسست تحت مظلة قانون ضمانات وحوافز الاستثمار رقم 8 لسنة 1997 ومؤسسها السيد / ايهاب سعيد خليل ابراهيم";
const url = "https://wood-maker.net/";
const logoImage = "images/Logo/social_logo.png";
const site_name = "Wood Maker";
</script>
<template>
    <!-- Head -->
    <Head>
        <!-- SEO META Tags -->
        <meta name="keywords" :content="keywords">
        <meta name="author" :content="author">
        <meta name="description" :content="description">

        <!--  Essential META Tags For Social Media Websites -->
        <meta property="og:title" :content="ogTitle">
        <meta property="og:type" content="website" />
        <meta property="og:image" :content="url+logoImage">
        <meta property="og:url" :content="url">
        <meta name="twitter:card" content="summary_large_image">

        <!--  Non-Essential, But Recommended For Social Media Websites -->
        <meta property="og:site_name" :content="site_name" />
        <meta property="og:description" :content="description"/>
        <meta name="twitter:image:alt" :content="site_name">
        <meta name="msapplication-TileColor" content="#f46e3b" />
        <meta name="theme-color" content="#f46e3b" />
    </Head>
    <Navbar class="d-none d-xl-block" />
    <MobileNavbar class="d-block d-xl-none" />
    <MediaLinks />
    <slot />
    <Footer :locations="locations" />
    <ScrollToTopButton />
</template>
<style lang="scss" scoped></style>
