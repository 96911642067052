<script setup>
import { onBeforeUnmount, onMounted } from "vue";
import { scrollToTop } from "@/Modules/Dom";

const scrollTopVisibility = () => {
    if (window.scrollY <= 300) {
        document.querySelector(".scrollTop").style.display = "none";
    } else {
        document.querySelector(".scrollTop").style.display = "flex";
    }
};

function Scroll() {
    scrollTopVisibility();
}

onMounted(() => {
    window.addEventListener("scroll", Scroll);
    scrollTopVisibility();
});

onBeforeUnmount(() => {
    window.removeEventListener("scroll", Scroll);
});
</script>

<template>
    <span class="scrollTop" @click="scrollToTop()"><i class="fa-solid fa-angles-up"></i></span>
</template>

<style lang="scss" scoped>
@-webkit-keyframes arrow-up-down {
    0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(-5px);
    }

    75% {
        transform: translateY(5px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes arrow-up-down {
    0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(-5px);
    }

    75% {
        transform: translateY(5px);
    }

    100% {
        transform: translateY(0);
    }
}

.scrollTop {
    position: fixed;
    bottom: 30px;
    right: 30px;
    font-size: 20px;
    background-color: $primary;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: none;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    color: #fff;
    transition: all ease-in-out 0.2s;
    z-index: 99999;
    animation: arrow-up-down 1.5s linear infinite;

    &:hover {
        -webkit-animation: arrow-up-down 1.5s linear infinite;
        animation: arrow-up-down 1.5s linear infinite;
        background-color: #ffa559;
    }
}</style>
