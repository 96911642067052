<script setup></script>
<template>
    <div class="mediaLinks">
        <!-- Default dropend button -->
        <button type="button" class="" data-bs-toggle="dropdown" aria-expanded="false" aria-label="mind shift media links">
            <i class="fa-solid fa-chevron-left"></i>
        </button>
        <ul class="mediaDrop dropdown-menu">
            <!-- Dropdown menu links -->
            <li>
                <a href="tel:+201113071110">
                    <i class="fa-solid fa-phone-volume"></i>
                </a>
            </li>
            <li>
                <a href="https://wa.me/+201113071110" aria-label="whats app number" target="_blank">
                    <i class="fa-brands fa-whatsapp"></i>
                </a>
            </li>
            <li>
                <a target="_blank" href="mailto:info@wood-maker.net" aria-label="email">
                    <i class="fa-solid fa-envelope"></i>
                </a>
            </li>
        </ul>
    </div>
</template>

<style lang="scss" scoped>
.mediaLinks {
    position: fixed;
    z-index: 999;
    top: 30%;
    right: 0px;

    button {
        display: flex;
        list-style: none;
        padding: 12px;
        color: #fff;
        background-color: $primary;
        border: none;
        border-radius: 5px 0 0px 5px;
        font-size: 15px;
        position: fixed;
        right: 0;
        top: 25%;
    }

    ul {
        transition: all 1s;
        inset: 0 auto auto auto !important;
        position: relative !important;

        &.show {
            transform: translate3d(-37px, -90px, 0px) !important;
            transition: all 1s;
        }

        width: 150px;
        transform: translate3d(150px, -90px, 0px) !important;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-direction: column;
        background-color: transparent;
        border: none;

        li {
            width: fit-content;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: $primary;
                color: #fff;
                font-size: 18px;
            }

            &:first-child {

                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-color: #19a7a4;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }

            &:nth-child(2) {


                a {
                    position: absolute;
                    top: 52px;
                    left: 40px;
                    background-color: #25D366;
                }
            }

            &:last-child {

                a {
                    position: absolute;
                    top: 105px;
                    right: -1px;
                    background-color: #0489c9;
                }
            }
        }
    }
}
</style>
