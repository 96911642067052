<script setup>
import { Link } from "@inertiajs/vue3";
import { onBeforeUnmount, onMounted, ref } from "vue";
import ApplicationLogo from "../../Components/ApplicationLogo.vue";
import { loadLanguageAsync, getActiveLanguage, trans } from "laravel-vue-i18n";
import { locale } from "@/Modules/Locale";

const navbar = ref(null);
const fixedNavbar = ref(false);
let navbarOffsetTop = null;

const scrollTopVisibility = () => {
  if (window.scrollY >= navbarOffsetTop) {
    fixedNavbar.value = true;
  } else {
    fixedNavbar.value = false;
  }
};

function Scroll() {
  scrollTopVisibility();
}

onMounted(() => {
  navbarOffsetTop = navbar.value.offsetTop;
  window.addEventListener("scroll", Scroll);
  scrollTopVisibility();
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", Scroll);
});

defineExpose({ fixedNavbar });
</script>
<template>
  <nav
    class="px-3 py-0 navbar navbar-expand-lg bg-body-tertiary bg-light"
    data-bs-theme="light"
    id="navbar"
    :class="{ 'sticky-top': fixedNavbar }"
    ref="navbar"
  >
    <!-- main navbar -->
    <div class="justify-between container-fluid d-flex text-capitalize">
      <Link class="p-0 m-0 navbar-brand" :href="route('home.index')">
        <ApplicationLogo width="80" />
      </Link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse justify-content-between navbar-collapse"
        id="navbarNav"
      >
        <ul class="px-3 navbar-nav">
          <li class="nav-item">
            <Link
              class="nav-link"
              :class="{
                active: route().current(`home.index`),
              }"
              aria-current="page"
              :href="route('home.index')"
              >{{ $t("navbar.home") }}
            </Link>
          </li>
          <li class="nav-item">
            <Link
              class="nav-link about"
              :href="route('aboutus.index')"
              :class="{
                active: route().current(`aboutus.index`),
              }"
            >
              {{ $t("navbar.about") }}
            </Link>
          </li>
          <li class="nav-item">
            <Link
              class="nav-link about"
              :href="route('news.index')"
              :class="{
                active: route().current(`news.*`),
              }"
              >{{ $t("navbar.news") }}</Link
            >
          </li>
          <li class="nav-item">
            <Link
              class="nav-link"
              :class="{
                active: route().current(`services.index`),
              }"
              :href="route('services.index')"
              >{{ $t("navbar.catalog") }}
            </Link>
          </li>
          <li class="nav-item">
            <Link
              class="nav-link"
              :href="route('project.index')"
              :class="{
                active: route().current(`project.index`),
              }"
              >{{ $t("navbar.projects") }}
            </Link>
          </li>
          <li class="nav-item">
            <Link
              class="nav-link"
              :href="route('job.index')"
              :class="{
                active: route().current(`job.*`),
              }"
              >{{ $t("navbar.job") }}</Link
            >
          </li>
          <li class="nav-item">
            <Link
              class="nav-link"
              :class="{
                active: route().current(`contact.index`),
              }"
              :href="route('contact.index')"
              >{{ $t("navbar.contact") }}</Link
            >
          </li>
        </ul>
        <!-- setting section  -->
        <section class="gap-3 mx-3 setting d-flex">
          <div
            v-if="$page.props.auth.user"
            class="lang d-flex align-items-center"
          >
            <div class="dropdown">
              <button
                class="dropdown-toggle text-capitalize"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="mb-1 fa-solid fa-user fs-4"></i>
              </button>
              <ul
                class="dropdown-menu"
                :class="{ ar: getActiveLanguage() == 'ar' }"
              >
                <li>
                  <Link
                    :href="route('profile.edit')"
                    class="dropdown-item text-capitalize"
                  >
                    {{ $t("navbar.profile") }}
                  </Link>
                </li>
                <li>
                  <Link
                    v-if="$page.props.auth.isAdmin"
                    :href="route('admin.index')"
                    class="dropdown-item text-capitalize"
                  >
                    {{ $t("navbar.dashboard") }}
                  </Link>
                </li>
                <li>
                  <Link
                    :href="route('logout')"
                    method="post"
                    as="button"
                    class="dropdown-item text-capitalize text-danger"
                  >
                    {{ $t("navbar.logout") }}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <Link v-else :href="route('login')" class="login">{{
            $t("navbar.login")
          }}</Link>
          <div class="lang d-flex align-items-center">
            <div class="dropdown">
              <i class="mx-1 fa-solid fa-globe"></i>
              <button
                class="dropdown-toggle text-capitalize"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ getActiveLanguage() == "en" ? "English" : "العربية" }}
              </button>
              <ul
                class="dropdown-menu"
                :class="{ ar: getActiveLanguage() == 'ar' }"
              >
                <li>
                  <button
                    class="dropdown-item text-capitalize"
                    @click="locale('en')"
                  >
                    {{ $t("admin.navbar.en") }}
                  </button>
                </li>
                <li>
                  <button
                    class="dropdown-item text-capitalize"
                    @click="locale('ar')"
                  >
                    {{ $t("admin.navbar.ar") }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.sticky-top {
  transition: all 1s;
}

nav {
  background-color: #fff !important;
  transition: all 0.5s;

  .navbar-collapse {
    ul {
      .nav-item {
        .active {
          color: $primary;
        }
      }

      li {
        position: relative;

        .nav-link {
          position: relative;
          color: $secondary;
          font-weight: bold !important;
          position: relative;
          overflow: hidden;
          transition: color 0.5s;

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }

  .setting {
    padding: 10px 0;

    .login {
      padding: 8px 20px;
      border-radius: 25px;
      border: none;
      background-color: $primary;
      color: #fff;
      display: block;
      text-align: center;
    }

    .lang {
      color: $gray;
      transition: color 0.5s;

      &:hover {
        color: $primary;
      }

      .dropdown-menu {
        transform: translate3d(-30px, 9px, 0px) !important;
        padding: 0;

        &.ar {
          transform: translate3d(-36px, 8px, 0px) !important;
        }

        .dropdown-item {
          display: flex;
          justify-content: center;
          padding: 10px 20px;
          transition: color 0.5s;

          &:hover {
            color: $primary;
            background-color: $whiteSmoke !important;
          }
        }
      }
    }
  }
}

.soon:hover {
  &::after {
    content: "Soon";
    position: absolute;
    background-color: $primary;
    border-radius: 5px;
    padding: 8px 16px;
    top: 110%;
    width: 130px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    left: -50%;
    transition: all 1s;
  }

  &::before {
    content: "";
    position: absolute;
    top: 62%;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent $primary transparent;
    right: 38%;
    z-index: 1;
  }
}
</style>
