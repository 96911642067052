<script setup>
import { useAttrs } from 'vue';

const props = defineProps({
    locations: {
        type: Object,
        required: true,
    },
});

const $attrs = useAttrs();
</script>

<template>
    <div v-bind="$attrs" class="pills-component">
        <ul class="gap-3 mb-3 nav nav-pills d-flex flex-column" id="pills-tab" role="tablist">
            <li v-for="(location, index) in locations" :key="location.id" class="nav-item" role="presentation">
                <button class="m-0 mx-auto nav-link d-flex align-items-center" :class="{ 'active': index == 0 }"
                    id="pills-home-tab" data-bs-toggle="pill" :data-bs-target="'#' + location.id" type="button"
                    role="tab" :aria-controls="location.id" aria-selected="true">
                    <i class="mx-2 fa-solid fa-location-dot"></i>
                    <h4 class="m-0 text-capitalize">{{ location.name }}</h4>
                </button>
            </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
            <div v-for="(location, index) in locations" :key="location.id" class="tab-pane fade show "
                :class="{ 'active': index == 0 }" :id="location.id" role="tabpanel" aria-labelledby="pills-home-tab"
                tabindex="0">
                <iframe :src="location.iframe_src" class="col-12" :title="location.name" height="250px"
                    style="border-radius: 5px" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.pills-component {
    .mb-3 {
        justify-content: center;
    }

    .nav-pills .nav-link {
        color: white !important;
        border-radius: 0;
        padding: 0 8px 8px !important;
        border-radius: 10px;
        margin-left: 8px;
    }

    .nav-pills .nav-link.active {
        color: $primary !important;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
        background-color: transparent !important;
    }
}
</style>
